const elementHeight = (el, variableName) => {
  if (el) {
    function initListener() {
      const elementHeight = el.offsetHeight;
      document.querySelector(':root').style.setProperty(`--${variableName}`, `${elementHeight}px`);
    }

    window.addEventListener('DOMContentLoaded', initListener);
    window.addEventListener('resize', initListener);
  }
}

document.addEventListener("DOMContentLoaded", function (){
  const vars = {
    header: document.querySelector('header'),
    burgers: document.querySelectorAll('.burger'),
    mainNav: document.querySelector('.main-nav'),
    body: document.body,
  }

  const {header,burgers,mainNav,body} = vars;

  elementHeight(header, 'header-height');

  function toggleMenu() {
    mainNav.classList.toggle('active');
    body.classList.toggle('no-scroll');
    burgers.forEach(burger => burger.classList.toggle('active'));
  }

  burgers.forEach(burger => {
    burger.addEventListener('click', function () {
      toggleMenu();
    });
  });

  document.addEventListener('click', function (e) {
    if (!mainNav.contains(e.target) && !e.target.closest('.burger') && mainNav.classList.contains('active')) {
      toggleMenu();
    }
  });

})



